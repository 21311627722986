@import "@/shared/ui/config/scss/_index";

.news {
  margin-bottom: 85px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 23px;
    @media (max-width: 950px) {
      margin-bottom: 80px;
      flex-direction: column;
      gap: 20px;
    }

    @media (max-width: 768px) {
    }
  }

  &__title {
    @media (max-width: 950px) {
      margin-bottom: 10px;
    }
  }

  &__button {
    &.active {
      &::before {
        opacity: 1;
      }
    }

    @media (max-width: 950px) {
      width: 100%;
      max-width: 372px;
      font-size: 25px;
    }
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  &__items-box {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    // gap: 38px 32px;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(415px, 1fr));
    grid-gap: 38px 32px;
    grid-auto-flow: dense;
    @media (max-width: 950px) {
      grid-template-columns: repeat(auto-fill, minmax(700px, 1fr));
      gap: 20px;
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    &.visible-all {
      .news__item {
        display: block;
      }
    }
  }

  &__item {
    display: none;
    border-radius: 12px;
    // width: 416px;
    overflow: hidden;
    transition: background-color 0.3s;
    text-decoration: none;

    @media (max-width: 950px) {
    }

    &:hover {
      background-color: #fcc649;
    }

    &:nth-child(-n + 6) {
      display: block;
    }
  }

  &__image {
    width: 100%;
    height: 235px;
    object-fit: cover;
    object-position: top;
    margin-bottom: 16px;
    @media (max-width: 950px) {
      height: 380px;
      margin-bottom: 30px;
    }
    @media (max-width: 768px) {
      height: 200px;
      margin-bottom: 16px;
    }
  }

  &__info {
  }
  &__item-title {
    margin-bottom: 16px;
    color: #414042;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100% */
    @media (max-width: 950px) {
      font-size: 40px;
      margin-bottom: 20px;
    }
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  &__item-text {
    color: #414042;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 125% */
    @media (max-width: 950px) {
      font-size: 30px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  &__info {
    padding: 10px 16px 16px;

    @media (max-width: 950px) {
      padding-bottom: 35px;
    }
    @media (max-width: 768px) {
      padding-bottom: 16px;
    }
  }
}
