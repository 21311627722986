@import "@/shared/ui/config/scss/_index";

.excursion {
  margin-top: 145px;
  margin-bottom: 145px;
  @media (max-width: 950px) {
    margin-bottom: 80px;
    margin-top: 50px;
  }
  &__wrap {
    border-radius: 24px;
    padding: 8px;
    border: 1px solid #eaedf3;
    box-shadow: 0 50px 50px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.3s;

    @media (max-width: 1100px) {
      // width: 70%;
      margin: 0 auto 60px;
    }
    @media (max-width: 950px) {
      padding: 24px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    &:hover {
      background-color: #fcc649;
      box-shadow: none;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 950px) {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding-right: 20px;
    padding-left: 20px;

    @media (max-width: 950px) {
      padding: 0 0px 20px 0px;
      width: 100%;
    }

    @media (max-width: 430px) {
      width: 100%;
      padding: 10px 0 10px 0;
    }
  }

  &__title-box {
    display: flex;
    align-items: center;
    gap: 10px 28px;
    margin-bottom: 15px;

    @media (max-width: 1100px) {
      width: 55%;
    }

    @media (max-width: 950px) {
      width: 100%;
      justify-content: space-between;
    }

    @media (max-width: 430px) {
      // flex-direction: column;
    }

    img {
      width: 88px;

      @media (max-width: 560px) {
        width: 60px;
      }
    }
  }

  &__title {
    letter-spacing: 0.5px;
    color: #414042;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    @media (max-width: 768px) {
      font-size: 40px;
    }

    @media (max-width: 560px) {
      font-size: 32px;
    }
  }

  &__subtitle {
    max-width: 500px;

    &,
    p {
      color: #414042;

      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: 0.2px;

      @media (max-width: 430px) {
        font-size: 16px;
      }
    }
  }

  &__box {
    position: relative;

    @media (max-width: 1100px) {
      width: 40%;
      display: block;
    }

    @media (max-width: 950px) {
      width: 100%;
    }
  }

  &__image {
    border-radius: 16px;
    height: 100%;
    object-fit: cover;

    @media (max-width: 1100px) {
      width: 100%;
      display: block;
    }
    @media (max-width: 768px) {
      border-radius: 10px;
    }
  }

  &__on-hover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 16px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover &__on-hover {
    opacity: 1;
  }

  &__watch-button {
    margin: auto;
    color: #fff;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 24px;
    border: 2px solid #fff;
    text-decoration: none;
    transition: all 0.3s;

    @media (max-width: 430px) {
      font-size: 14px;
      border-width: 1px;
    }

    &:hover {
      border-color: #ea5732;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
