@import "@/shared/ui/config/scss/_index";

.modernization {
  position: relative;
  margin-bottom: 130px;
  padding-bottom: 30px;

  &::after {
    content: url($srcSharedImagesPath + "decor/decoration-bg-2.png");
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 50%;
    @media (max-width: 1250px) {
      display: none;
    }
  }
  @media (max-width: 950px) {
    padding-bottom: 100px;
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: 48px;
    text-align: left;
    @media (max-width: 768px) {
      margin-bottom: 25px;
    }
  }

  &__content {
    display: flex;
    align-items: baseline;
    gap: 35px;
    @media (max-width: 1250px) {
      flex-wrap: wrap;
      gap: 50px;
    }
    @media (max-width: 1070px) {
      gap: 10px;
      justify-content: space-between;
    }
  }

  &__item {
    color: #363636;
    font-family: "Cera Pro";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    @media (max-width: 1250px) {
      width: 220px;
    }
    @media (max-width: 1070px) {
      width: 28%;
    }
    @media (max-width: 768px) {
      width: 40%;
    }
    @media (max-width: 480px) {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      text-align: left;

      br {
        display: none;
      }
    }

    span {
      color: #ea6333;
      text-align: center;
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @media (max-width: 480px) {
        font-size: 30px;
      }
    }
  }
}
