@import "@/shared/ui/config/scss/_index";

.accessories {
  &__wrap {
    position: relative;
    margin-bottom: 82px;
    @media (max-width: 950px) {
      margin-bottom: 60px;
    }
  }
  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    @media (max-width: 950px) {
      margin-bottom: 22px;
    }
    @media (max-width: 768px) {
      margin-bottom: 25px;
    }
  }

  &__title {
    @media (max-width: 768px) {
      text-align: left;
    }
  }

  &__item {
    padding: 16px 0;
    border-radius: 16px;
    background: #f4f4f4;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    transition: all 0.3s;

    @media (max-width: 950px) {
      padding-bottom: 50px;
    }

    &:hover {
      background: #fcc649;
    }
  }
  &__image {
    width: 192px;
    height: 192px;
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 950px) {
      width: 370px;
      height: 370px;
    }
    @media (max-width: 768px) {
      height: 310px;
    }
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  &__slider-title {
    color: #414042;
    text-align: center;
    font-family: "Cera Pro";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    padding-left: 5px;
    padding-right: 5px;
    @media (max-width: 950px) {
      font-size: 25px;
    }
    @media (max-width: 768px) {
      font-size: 15px;
    }
  }

  .swiper-slide {
    text-align: center;
    height: auto;
  }
}
