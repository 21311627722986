@import "@/shared/ui/config/scss/_index";

.reviews {
  margin-bottom: 160px;

  @media (max-width: 950px) {
    margin-bottom: 100px;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;

    @media (max-width: 950px) {
      margin-bottom: 60px;
    }

    @media (max-width: 768px) {
      margin-bottom: 25px;
    }
  }

  &__title {
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__button {
    @media (max-width: 430px) {
      width: 100%;
    }

    @media (max-width: 950px) {
      display: none;
    }

    &.active {
      &::before {
        opacity: 1;
      }
    }
  }

  &__items {
    display: flex;
    gap: 32px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &.-non-slider {
      .swiper-slide {
        display: none;

        &:nth-child(-n + 3) {
          display: block;
        }
      }

      .swiper-wrapper {
        transform: inherit !important;
        width: 100% !important;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        grid-gap: 32px;
        grid-auto-flow: dense;
      }

      &.visible-all {
        .swiper-slide {
          display: block;
        }
      }
    }
  }

  &__item {
    // width: calc(33.33% - (32px * 2 / 3));
    border-radius: 16px;
    background: #fafafa;
    padding: 0 24px 24px 24px;
    height: 100%;

    @media (max-width: 768px) {
      // width: 90%;
      margin: 0 auto;
    }

    @media (max-width: 430px) {
      width: 100%;
    }
  }
  &__item-image {
    display: block;
    margin: 0 auto 24px;
    object-fit: contain;
    max-width: 100%;
    width: 200px;
    height: 150px;
    @media (max-width: 950px) {
      width: 412px;
      height: 309px;
    }
    @media (max-width: 768px) {
      width: 200px;
      height: 150px;
    }
  }

  &__item-title {
    color: #414042;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 10px;
    @media (max-width: 950px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  &__item-subtitle {
    color: #aaa;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 24px;
    @media (max-width: 950px) {
      font-size: 30px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__item-text {
    color: #aaa;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: justify;

    @media (max-width: 950px) {
      font-size: 30px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .swiper-slide {
    height: auto;
    cursor: grab;
  }

  .arrow__box {
    @media (min-width: 950px) {
      display: none;
    }
  }
}
