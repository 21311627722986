@import "@/shared/ui/config/scss/_index";

.clients {
  padding-bottom: 165px;

  @media (max-width: 1300px) {
    background: none;
  }

  @media (max-width: 950px) {
    padding-bottom: 100px;
  }
  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 37px;
    @media (max-width: 950px) {
      margin-bottom: 60px;
    }
    @media (max-width: 768px) {
      margin-bottom: 25px;
    }
  }

  &__title {
    text-align: left;
  }

  &__image {
    max-width: 100%;
    transition: all 0.3s;
    @media (max-width: 951px) {
      width: 100%;
    }
    @media (min-width: 951px) {
      filter: grayscale(1);
    }

    &:hover {
      filter: grayscale(0);
    }
  }

  &__slider {
    .swiper-wrapper {
      // @media (max-width: 950px) {
      //   gap: 30px;
      // }
      .swiper-slide {
        @media (max-width: 950px) {
          // flex: 1 1 33.33%;
          // padding: 19px;
        }
      }
    }

    &.-non-slider {
      @media (min-width: 951px) {
        .swiper-wrapper {
          transform: inherit !important; /* Normalize the position of the slider */
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
          grid-gap: 32px;
          grid-auto-flow: dense;
        }
        .swiper-slide {
          width: inherit !important; /* Initialize the width of the slides */
        }
        .swiper-slide-duplicate {
          /* Remove slide duplicates（loop:trueの場合） */
          display: none;
        }
      }
    }
  }
}
