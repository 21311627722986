@import "@/shared/ui/config/scss/_index";

.promo {
  min-height: 90vh;
  position: relative;
  padding-top: 230px;
  padding-bottom: 100px;

  @media (max-width: 950px) {
    min-height: 600px;
    padding-bottom: 75px;
    padding-top: 160px;
  }

  & > .container {
    @media (min-width: 481px) {
      position: relative;
      width: 93%;
      padding: 16px;
      background-color: #00000040;
      border-radius: 20px;
    }
  }

  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
    // z-index: -1;
    @media (max-width: 480px) {
      display: none;
    }
  }

  &__column-left {
    position: relative;
  }

  &__title {
    color: var(--white, #fff);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    max-width: 950px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      font-size: 36px;
    }

    @media (max-width: 480px) {
      color: $color-text-dark;
    }
  }

  &__wrap-description {
    margin-bottom: 30px;
    ul {
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        gap: 5px;
      }
      li {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        @media (max-width: 768px) {
          font-size: 16px;
        }
        @media (max-width: 480px) {
          color: $color-text-dark;
        }
      }
    }
  }

  &__button {
    @media (max-width: 950px) {
      max-width: 372px;
      width: 100%;
    }
  }
}
