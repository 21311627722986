@import "@/shared/ui/config/scss/_index";

.portfolio {
  margin-bottom: 160px;
  margin-top: -300px;

  @media (max-width: 1100px) {
    margin-top: 0px;
  }

  @media (max-width: 950px) {
    margin-top: 80px;
    margin-bottom: 100px;
  }

  &__title {
    text-align: center;
    margin-bottom: 48px;

    @media (max-width: 951px) {
      display: block;
      margin-right: 35px;
      text-align: left;
    }
    @media (max-width: 768px) {
      margin-bottom: 25px;
      text-align: left;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    @media (max-width: 950px) {
      gap: 25px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 228px;
    width: 304px;
    padding: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    color: $color-text-inverse;
    font-size: 24px;
    overflow: hidden;
    text-decoration: none;

    @media (max-width: 950px) {
      width: 48%;
      height: 280px;
    }

    @media (max-width: 768px) {
      display: none;
    }

    @media (max-width: 680px) {
      width: 47%;
      height: 225px;
    }

    @media (max-width: 480px) {
      width: 100%;
    }

    &:nth-child(-n + 7) {
      @media (max-width: 768px) {
        display: block;
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 16px;
      background: linear-gradient(
        180deg,
        rgba(53, 53, 53, 0.3) 0%,
        #ffd04d 100%
      );
      opacity: 0;
      transition: opacity 0.3s;
      z-index: -1;
    }

    &:hover:before {
      opacity: 1;
      z-index: 1;
    }

    &.active {
      &::before {
        opacity: 1;
        z-index: 1;
      }
    }

    &-show-more {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      background: linear-gradient(
        267deg,
        $color-primary-hover 3.37%,
        $color-primary 106.43%
      );
      color: #000;

      @media (max-width: 768px) {
        display: flex;
      }

      &:before {
        background: $color-primary;
      }

      & > svg {
        position: relative;
        z-index: 2;
      }

      & > .portfolio__item-text {
        color: $color-text;
        text-shadow: none;
      }
    }
  }
  &__item-text {
    position: relative;
    z-index: 10;
    color: var(--white, $color-text-inverse);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100% */
    text-shadow: -1px 1px 2px #000;

    @media (max-width: 950px) {
      font-size: 35px;
    }

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
  &__arrow {
    position: relative;
    margin-top: 10px;
    z-index: 10;
  }
}
