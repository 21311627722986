$templateRootPath: "Root/";
$srcSharedImagesPath: "@/shared/images/";

$color-primary: #fcc649;
$color-primary-hover: #ea5732;
$color-secondary: #ea6333;
$color-text: #414042;
$color-text-dark: #3e3e3f;
$color-text-light: #484748;
$color-text-grey: #aaa;
$color-text-inverse: #fff;

$white: #ffffff;
$black: #000000;

// $font-size-basic: 18;
// $font-size-basic-xs: 16;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  s: 421,
  sm: 544,
  md: 768,
  lg: 921,
  lg2: 992,
  xl: 1200,
  xxl: 1440,
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// using: @include media-breakpoint-up(md) {...}
