@import "@/shared/ui/config/scss/_index";

.populars {
  background: url($srcSharedImagesPath + "decor/decoration-bg.svg") no-repeat
    calc(50% - 100px) top;
  padding-bottom: 490px;

  @media (max-width: 1100px) {
    background: linear-gradient(
      56deg,
      $color-primary-hover 8.46%,
      $color-primary 83.58%
    );
    padding-bottom: 85px;
    padding-top: 75px;
    margin-bottom: 80px;
  }

  @media (max-width: 560px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__title-box {
    display: flex;
    justify-content: flex-end;
    gap: 33%;
    align-items: center;
    padding-top: 60px;
    margin-bottom: 48px;
    position: relative;

    @media (max-width: 1100px) {
      justify-content: space-between;
      gap: 10px;
      padding-top: 0;
    }
  }
  &__title {
    color: $color-text-inverse;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: var(--white, $color-text-inverse);
    text-align: center;

    @media (max-width: 1100px) {
      position: relative;
      left: 0;
      transform: translateX(-0%);
      text-align: left;
    }
  }

  &__item {
    position: relative;
    border-radius: 16px;
    background: rgba(255, 208, 77, 0.3);
    padding: 25px 0;
    color: $color-text-inverse;
    text-decoration: none;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 16px;
      background: linear-gradient(
        180deg,
        rgba(255, 208, 77, 0) 0%,
        #ffd04d 100%
      );
      opacity: 0;
      transition: opacity 0.3s;
      z-index: -1;
    }

    &:hover:before {
      opacity: 1;
    }
  }

  &__image {
    margin-bottom: 24px;
    width: 304px;
    height: 304px;
    margin-left: auto;
    max-width: 100%;
    object-fit: contain;
  }

  &__item-info {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__item-title {
    color: var(--white, $color-text-inverse);
    text-align: center;
    font-family: "Cera Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__arrow-box {
    top: 80px;
    right: 10px;
  }

  .swiper-slide {
    text-align: center;
    height: auto;
  }
}
