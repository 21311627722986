@import "@/shared/ui/config/scss/_index";

// @import "@/widgets/layouts/header/index";
// @import "@/widgets/nav/headerNav/index";
// @import "@/widgets/layouts/sidebar/index";
// @import "@/widgets/layouts/main/index";
// @import "@/widgets/layouts/footer/index";
// @import "@/widgets/nav/sidebarNav/index";
// @import "@/features/sidebarForm/index";
// @import "@/widgets/nav/footerNav/index";
// @import "@/shared/ui/popup/_index";

.main.index-page {
  margin-top: 0;
}

// @import "./blocks/promo";
// @import "./blocks/excursion";
// @import "./blocks/accessories";
// @import "./blocks/populars";
// @import "./blocks/portfolio";
// @import "./blocks/software";
// @import "./blocks/clients";
// @import "./blocks/reviews";
// @import "./blocks/modernization";
// @import "./blocks/news";
// @import "./blocks/callback";
