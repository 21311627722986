@import "@/shared/ui/config/scss/_index";

.software {
  position: relative;
  text-align: center;
  margin-bottom: 160px;

  @media (max-width: 950px) {
    margin-bottom: 80px;
  }

  &__title {
    text-align: center;
    margin-bottom: 10px;
  }

  &__subtitle {
    margin-bottom: 60px;
    color: $color-secondary;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 166%; /* 166.667% */
    letter-spacing: 0.24px;
    @media (max-width: 950px) {
      font-size: 30px;
      margin-bottom: 20px;
    }

    @media (max-width: 768px) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  &__content {
  }

  &__text {
    margin: 30px auto 0;
  }

  &__item {
    position: relative;
    transition: background 0.3s;
    padding: 16px 6px;
    text-decoration: none;
    @media (max-width: 950px) {
      padding-top: 60px;
      padding-bottom: 40px;
    }

    @media (max-width: 768px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #fff 0%, #ffd04d 100%);
      opacity: 0;
      transition: opacity 0.3s;
      border-radius: 24px;
      z-index: -1;
    }

    &:hover:before {
      opacity: 1;
      color: #fff;
    }
  }

  &__item-title {
    color: #414042;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 150% */
    letter-spacing: 0.2px;
    @media (max-width: 950px) {
      font-size: 35px;
    }
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  &__item-box {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(415px, 1fr));
    grid-gap: 32px;
    grid-auto-flow: dense;

    @media (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
    }
    @media (max-width: 480px) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }

  &__image {
    margin-bottom: 20px;
    max-width: 100%;
    height: 300px;
    object-fit: contain;
    @media (max-width: 950px) {
      height: 455px;
      margin-bottom: 60px;
    }

    @media (max-width: 768px) {
      height: 300px;
      margin-bottom: 20px;
    }
  }
}
